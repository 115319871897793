.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root,
.App,
.App > div,
.outer-wrap,
.ant-row,
.ant-col {
  height: 100%;
}

@media (min-width: 920px) {
  .ant-col.data {
    max-width: 40% !important;
  }
}

.download-csv {
  top: 50px;
  position: relative;
}

.check-my-case {
  top: 60px;
  position: relative;
}

.burger {
  display: none;
}

.save-button {
  margin: 80px auto;
  background-color: #61dafb;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  font-weight: bold;
}
.burger-button {
  background-color: #ffffff;
}

@media (max-width: 920px) {
  .ant-col.data {
    display: none;
  }
  .burger {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 20px;
  }
}

.up-front-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.ant-col.map > input {
  width: 50%;
  padding-left: 16px;
  position: absolute;
  top: 10px;
  left: 200px;
  height: 40px;
  z-index: 1;
}


button.ant-typography-danger {
  border: none;
  background: none;
  padding: 0;
}

.question-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
 
}

.cascader-form-wrapper {
  margin-left: 10px;
 
}






